import { TModuleSettings } from "@src/stores/models/types";
import { Route } from "nextjs-routes";

type ModuleSettings = Omit<TModuleSettings, "__typename">;

/**
 * Loop Map and get my first allowed welcome page.
 */
export type TModules = keyof ModuleSettings;
export const welcomePageByModule: [TModules, Route["pathname"]][] = [
  ["projects", "/projects"],
  ["budgetovac", "/budgets/export"],
  ["planovac", "/resource-planning"],
  ["invoices", "/invoices/for-invoicing"],
  ["expenses", "/expenses"],
  ["billing_plan", "/invoices/billing-plan"],
  ["clients", "/brands"],
  ["contractors", "/contractors"],
  ["time_tracking", "/time-tracking/day"],
  ["reports", "/reports/time-report"],
  ["documents", "/documents"],
];

/**
 * Check if user has any permission for the page.
 */
export type TPages = TModules | "users" | "settings" | "tasks" | "templates";
